<template>
  <div v-if="renderDropdown">
    <div class="dropdown">
      <button class="ml-2 btn btn-outline-primary" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i aria-hidden="true" class="fal fa-ellipsis-v text-2"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <a v-if="showSelectJobs" class="dropdown-item" :href="selectJobsUrl">Select Jobs For New Campaign</a>
        <a v-if="renderUpdateBudget" class="dropdown-item" href="#" data-toggle="modal" data-target="#update-budget-modal">Update Budget</a>
        <a class="dropdown-item" href="https://support.jobtarget.com/s/topic/0TO5x0000015Qg0GAE/programmatic" target="_blank">FAQ</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreDropdown",
  props: {
    pageloadData: {
      type: [Object, null],
      required: true
    },
    cycle: {
      type: Object
    },
    renderUpdateBudget: {
      type: Boolean
    },
    renderDropdown: {
      type: Boolean
    },
  },
  data() {
    return {
      selectJobsUrl: `${process.env.VUE_APP_PGM_UI}/select-jobs`,
    }
  },
  computed: {
    showSelectJobs() {
      return !this.pageloadData ? false : this.pageloadData.has_scheduled_campaigns;
    },
  },
};
</script>

<style scoped>
#dropdownMenuButton {
  background-color: #d5e7f8;
  border-color: #d5e7f8;
  padding: 1px 16px;
  color: #2f86dd;
}
</style>